<!--
 * @Description: 邮件下发
 * @Author: ChenXueLin
 * @Date: 2021-12-15 14:24:15
 * @LastEditTime: 2022-07-15 16:39:27
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="destMail">
              <el-input
                v-model="searchForm.destMail"
                placeholder="邮箱地址"
                title="邮箱地址"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="subject">
              <el-input
                v-model="searchForm.subject"
                placeholder="主题"
                title="主题"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="type">
              <e6-vr-select
                v-model="searchForm.type"
                :data="emailTypeList"
                placeholder="类型"
                title=""
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sendStatus">
              <e6-vr-select
                v-model="searchForm.sendStatus"
                :data="sendTypeList"
                placeholder="发送状态"
                title=""
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="sendTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="发送时间"
                ref="effectiveDatePicker"
                v-model="searchForm.sendTime"
                title="发送时间"
                :picker-options="pickerOptions('searchForm.sendTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="发送时间（始）"
                end-placeholder="发送时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="addTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="添加时间"
                ref="effectiveDatePicker"
                v-model="searchForm.addTime"
                title="添加时间"
                :picker-options="pickerOptions('searchForm.addTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="添加时间（始）"
                end-placeholder="添加时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 添加邮件弹框 -->
    <el-dialog
      v-dialogDrag
      title="添加邮件"
      :visible.sync="addDialog"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="editCustomerDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="edit-customer-dialog"
    >
      <el-form
        ref="addForm"
        label-width="120px"
        :model="addForm"
        :rules="addFormRules"
      >
        <el-form-item label="收件人" prop="destMail">
          <el-input
            v-model.trim="addForm.destMail"
            placeholder="收件人"
          ></el-input>
        </el-form-item>
        <el-form-item label="抄送" prop="ccMail">
          <el-input v-model.trim="addForm.ccMail" placeholder="抄送"></el-input>
        </el-form-item>
        <el-form-item label="主题" prop="subject">
          <el-input
            v-model.trim="addForm.subject"
            placeholder="主题"
          ></el-input>
        </el-form-item>
        <el-form-item label="预发送时间" prop="preSendTimeTimestamp">
          <el-date-picker
            v-model="addForm.preSendTimeTimestamp"
            type="datetime"
            placeholder="选择预发送时间"
            value-format="timestamp"
            popper-class="special"
            :picker-options="pickerOptions1"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <e6-vr-select
            v-model="addForm.type"
            :data="emailTypeList"
            placeholder="类型"
            title=""
            clearable
            :props="{
              id: 'codeValue',
              label: 'codeName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="发送内容" prop="cont">
          <el-input
            v-model.trim="addForm.cont"
            placeholder="备注"
            type="textarea"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getEmailList, sendEmail, findDownList } from "@/api";
import { printError } from "@/utils/util";
import { emailsValid } from "@/utils/validate";

export default {
  name: "emailManage",
  components: {},
  mixins: [listPage, listPageReszie, base],
  data() {
    // 验证邮箱的规则
    var checkEmail = (rule, value, cb) => {
      // 验证邮箱正则表达式
      const regEmail = /^(\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*;)*(\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*;?)?$/;
      if (regEmail.test(value) || "" === value) {
        return cb();
      } else {
        cb(new Error("请输入正确的抄送地址"));
      }
    };
    return {
      editCustomerDialogLoading: false,
      loading: false,
      total: 0,
      emailTypeList: [], //邮件类型
      sendTypeList: [], //发送状态
      searchForm: {
        destMail: "", //邮箱地址
        subject: "", //主题
        type: "", //类型
        sendStatus: "", //发送状态
        addTime: [],
        addTimeBeginTimestamp: "",
        addTimeEndTimestamp: "",
        sendTime: [],
        sendTimeBeginTimestamp: "",
        sendTimeEndTimestamp: "",
        pageIndx: 1,
        pageSize: 20
      },
      selColumnId: [],
      columnData: [
        {
          fieldName: "destMail",
          display: true,
          fieldLabel: "主送地址",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "ccMail",
          display: true,
          fieldLabel: "抄送地址",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "subject",
          display: true,
          fieldLabel: "主题",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "cont",
          display: true,
          fieldLabel: "内容",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "typeName",
          display: true,
          fieldLabel: "类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "addTime",
          display: true,
          fieldLabel: "添加时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sendTime",
          display: true,
          fieldLabel: "发送时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "preSendTime",
          display: true,
          fieldLabel: "预发送时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sendStatus",
          display: true,
          fieldLabel: "发送状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "readStatus",
          display: true,
          fieldLabel: "读取状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      addDialog: false,
      pickerOptions1: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp < nowTime;
        }
      },
      addForm: {
        destMail: "", //收件人
        ccMail: "", //抄送
        subject: "", //主题
        preSendTimeTimestamp: "", //预计发送时间
        type: "", //类型
        cont: "" //内容
      },
      addFormRules: {
        destMail: emailsValid.required({
          requiredMsg: "请输入收件人",
          message: "邮箱格式不正确",
          trigger: ["blur", "change"]
        }),
        ccMail: { required: false, validator: checkEmail, trigger: "blur" },
        subject: [
          {
            required: true,
            message: "请输入主题",
            trigger: ["blur", "change"]
          }
        ],
        preSendTimeTimestamp: [
          {
            required: true,
            message: "请选择预发送时间",
            trigger: ["blur", "change"]
          }
        ],
        type: [
          {
            required: true,
            message: "请选择类型",
            trigger: ["blur", "change"]
          }
        ],
        cont: [
          {
            required: true,
            message: "请输入发送内容",
            trigger: ["blur", "change"]
          }
        ]
      },
      refTable: "tableList",
      queryListAPI: getEmailList
    };
  },
  computed: {},
  watch: {
    // 添加时间
    "searchForm.addTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.addTime = this.searchForm.addTime.reverse();
        }
        this.searchForm.addTimeBeginTimestamp = val[0] || "";
        this.searchForm.addTimeEndTimestamp = val[1] || "";
      }
    },
    // 发送时间
    "searchForm.sendTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.sendTime = this.searchForm.sendTime.reverse();
        }
        this.searchForm.sendTimeBeginTimestamp = val[0] || "";
        this.searchForm.sendTimeEndTimestamp = val[1] || "";
      }
    }
  },
  created() {
    var data = new Date(); //本月
    data.setDate(1);
    data.setHours(0);
    data.setSeconds(0);
    data.setMinutes(0);
    this.searchForm.addTime[0] = data.getTime();
    console.log("本月第一天0点" + data.getTime());
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [findDownList(["emailType", "sendType"])];
        let [emilRes] = await Promise.all(promiseList);
        //邮件类型下拉框
        this.emailTypeList = this.getFreezeResponse(emilRes, {
          path: "data.emailType"
        });
        //发送状态下拉框
        this.sendTypeList = this.getFreezeResponse(emilRes, {
          path: "data.sendType"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },

    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.limitId,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    /******新增*******/

    handleAdd() {
      this.addDialog = true;
    },
    handleConfirm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.addReply();
        }
      });
    },
    //创建请求
    async addReply() {
      try {
        this.loading = true;
        let res = await sendEmail(this.addForm);
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.queryList();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.addForm = {
        destMail: "", //收件人
        ccMail: "", //抄送
        subject: "", //主题
        preSendTimeTimestamp: "", //预计发送时间
        type: "", //类型
        cont: "" //内容
      };
      this.$refs.addForm.resetFields();
      this.addDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-dialog__body {
    padding: 20px 20px 0;
    .el-form {
      .el-input {
        width: 220px;
      }
    }
  }
}
</style>
